





































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class MainHeader extends Vue {
  Rythm = require("rythm.js");
  navOpen = false;
}
