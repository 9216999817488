import Vue from "vue";
import ExternalFacingView from "../components/shared/ExternalFacingView.vue"
import Nature from "../components/Nigerian.vue"
import Rap from "../components/shared/MainPage/Rap.vue"
import About from "../components/About.vue"
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "*",
    name: "Not Found",
    redirect: () => "/not-found",
  },
  {
    path: "/",
    component: ExternalFacingView,
    children: [
      {
        path: "",
        name: "Rap",
        component: Rap,
      },
      {
        path: "nature",
        name: "Nature",
        component: Nature,
      },
      {
        path: "about",
        name: "About",
        component: About,
      },
      
      // {
      //   path: "reset",
      //   name: "Reset Password",
      //   component: ResetPassword,
      // },
      // {
      //   path: "forgot-password",
      //   name: "Forgot Password",
      //   component: ForgotPassword,
      // },
    ],
  }
]

const router = new VueRouter({
  routes,
});

export default router;