




































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class MainPageBody extends Vue {
  model = 0;
  currentIndex = 0;
  beatModel = 0;
  Rythm = require("../../public/rythm.js");
  rythm = new this.Rythm();
  play = true;
  searchResult = 0;
  imagesIndex = 0;
  inputMP3: any | null = null;
  imagesInterval: number | null = null;
  playOwn = false;
  me: any = {
    name: "Andrej",
    instagram: "andrej_coder",
    about: "Developer/Producer",
  };
  songs: any[] = [
    {
      index: 0,
      mp3: "https://secure-everglades-49603.herokuapp.com/https://onedrive.live.com/download?cid=25E892A4FA706225&resid=25E892A4FA706225%21671592&authkey=AGfISPQNNoPK0rE",
      text: "Reggae Type Beat - 'Summer'",
    },
  ];
  belowImgs: any[] = [
    {
      img: "rasta/1.png",
      affiliate: [
        {
          img: "Zazzle/rasta-watch-1.png",
          link: "https://www.zazzle.com/z/aw1hlg70?rf=238257012446814623",
          text: "Conquering Lion Rasta Watch: $47.30",
        },
      ],
    },
    {
      img: "https://thumbs.dreamstime.com/b/rasta-lion-vector-theme-head-white-background-illustration-wallpaper-rastafarian-background-colors-92208266.jpg",
      affiliate: [
        {
          img: "Zazzle/shirt.png",
          link: "https://www.zazzle.com/lion_of_judah_rasta_t_shirt-235215761490366895?rf=238257012446814623",
          text: "Lion Of Judah - Rasta T-Shirt: $17.45",
        },
      ],
    },
    {
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSXA6nOtb6PDup8cmnIgaBeDfyYtg7QLI5XA&usqp=CAU",
      affiliate: [
        {
          img: "Zazzle/shirt-2.png",
          link: "https://www.zazzle.com/z/4izpeg7a?rf=238257012446814623",
          text: "Rasta Sunglasses T-Shirt: $18.45",
        },
      ],
    },
  ];
  // Put this in vuex state and it will remain playing even for this page (maybe)

  tryy(mp3: string) {
    if (this.inputMP3) {
      this.rythm.start();
      this.play = false;
      this.imagesInterval = setInterval(() => {
        this.imagesIndex++;
      }, 1500);
      return;
    }
    this.rythm.setMusic(mp3);
    this.rythm.pulseRatio = 0;
    this.rythm.start();
    this.play = false;
    this.imagesInterval = setInterval(() => {
      this.imagesIndex++;
    }, 1500);
  }
  onSearch(): void {
    if (this.searchResult || this.searchResult === 0) {
      this.currentIndex = this.searchResult;
      this.rythm.stop();
      this.play = true;
    }
  }
  onChange(): void {
    clearInterval(this.imagesInterval!);
    this.rythm.stop();
    this.searchResult = this.currentIndex;
    this.play = true;
  }
  handleFiles(event: any) {
    this.rythm.stop();
    var files = event.target.files;
    this.inputMP3 = URL.createObjectURL(files[0]);
    this.rythm.setMusic(this.inputMP3);
    this.rythm.start();
    this.play = false;
    // document.getElementById("src")?.setAttribute("src",  URL.createObjectURL(files[0]))
    // document.getElementById("audio")!.load();
  }
  onRemoveMP3(): void {
    this.inputMP3 = null;
    this.rythm.stop();
    this.play = true;
    (document.getElementById("upload") as HTMLInputElement)!.value = "";
  }
  destroyed(): void {
    this.rythm.stop();
  }
  sendToAffiliate(link: string): void {
    window.open(link, "_blank");
  }
  mounted(): void {
    scroll(0, 0);
    if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      location.reload();
      //test
    }
  }
  allowPlayOwn(): void {
    this.playOwn = true;
    document
      .getElementById("upload")!
      .addEventListener("change", this.handleFiles, false);
  }

  get showCondensedVersion(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }
}
