





















































































import { Component, Vue } from "vue-property-decorator";
import MainHeader from "./MainPage/Header.vue";

@Component({
  components: {
    MainHeader,
  },
})
export default class ExternalFacingView extends Vue {}
